import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Appartment1_Img1 from '../../assets/images/listing/listing4/1.webp';
import Appartment1_Img2 from '../../assets/images/listing/listing4/2.webp';
import Appartment1_Img3 from '../../assets/images/listing/listing4/3.webp';
import Appartment1_Img4 from '../../assets/images/listing/listing4/4.webp';
import Appartment1_Img5 from '../../assets/images/listing/listing4/5.webp';
import Appartment1_Img6 from '../../assets/images/listing/listing4/6.webp';
import Appartment1_Img7 from '../../assets/images/listing/listing4/7.webp';
import Appartment1_Img8 from '../../assets/images/listing/listing4/8.webp';
import Appartment1_Img9 from '../../assets/images/listing/listing4/9.webp';
import Appartment1_Img10 from '../../assets/images/listing/listing4/10.webp';
import Appartment1_Img11 from '../../assets/images/listing/listing4/11.webp';
import Appartment1_Img12 from '../../assets/images/listing/listing4/12.webp';
import Appartment1_Img13 from '../../assets/images/listing/listing4/13.webp';

import Appartment2_Img1 from '../../assets/images/listing/listing9/1.webp';
import Appartment2_Img2 from '../../assets/images/listing/listing9/2.webp';
import Appartment2_Img3 from '../../assets/images/listing/listing9/3.webp';
import Appartment2_Img4 from '../../assets/images/listing/listing9/4.webp';
import Appartment2_Img5 from '../../assets/images/listing/listing9/5.webp';
import Appartment2_Img6 from '../../assets/images/listing/listing9/6.webp';
import Appartment2_Img7 from '../../assets/images/listing/listing9/7.webp';
import Appartment2_Img8 from '../../assets/images/listing/listing9/8.webp';
import Appartment2_Img9 from '../../assets/images/listing/listing9/9.webp';
import Appartment2_Img10 from '../../assets/images/listing/listing9/10.webp';
import Appartment2_Img11 from '../../assets/images/listing/listing9/11.webp';
import Appartment2_Img12 from '../../assets/images/listing/listing9/12.webp';
import Appartment2_Img13 from '../../assets/images/listing/listing9/13.webp';
import Appartment2_Img14 from '../../assets/images/listing/listing9/14.webp';
import Appartment2_Img15 from '../../assets/images/listing/listing9/15.webp';

import Appartment3_Img1 from '../../assets/images/listing/listing13/1.webp';
import Appartment3_Img2 from '../../assets/images/listing/listing13/2.webp';
import Appartment3_Img3 from '../../assets/images/listing/listing13/3.webp';
import Appartment3_Img4 from '../../assets/images/listing/listing13/4.webp';
import Appartment3_Img5 from '../../assets/images/listing/listing13/5.webp';
import Appartment3_Img6 from '../../assets/images/listing/listing13/6.webp';
import Appartment3_Img7 from '../../assets/images/listing/listing13/7.webp';
import Appartment3_Img8 from '../../assets/images/listing/listing13/8.webp';
import Appartment3_Img9 from '../../assets/images/listing/listing13/9.webp';
import Appartment3_Img10 from '../../assets/images/listing/listing13/10.webp';
import Appartment3_Img11 from '../../assets/images/listing/listing13/11.webp';
import Appartment3_Img12 from '../../assets/images/listing/listing13/12.webp';
import Appartment3_Img13 from '../../assets/images/listing/listing13/13.webp';
import Appartment3_Img14 from '../../assets/images/listing/listing13/14.webp';
import Appartment3_Img15 from '../../assets/images/listing/listing13/15.webp';

import Appartment4_Img1 from '../../assets/images/listing/listing14/1.webp';
import Appartment4_Img2 from '../../assets/images/listing/listing14/2.webp';
import Appartment4_Img3 from '../../assets/images/listing/listing14/3.webp';
import Appartment4_Img4 from '../../assets/images/listing/listing14/4.webp';
import Appartment4_Img5 from '../../assets/images/listing/listing14/5.webp';
import Appartment4_Img6 from '../../assets/images/listing/listing14/6.webp';
import Appartment4_Img7 from '../../assets/images/listing/listing14/7.webp';
import Appartment4_Img8 from '../../assets/images/listing/listing14/8.webp';
import Appartment4_Img9 from '../../assets/images/listing/listing14/9.webp';
import Appartment4_Img10 from '../../assets/images/listing/listing14/10.webp';
import Appartment4_Img11 from '../../assets/images/listing/listing14/11.webp';
import Appartment4_Img12 from '../../assets/images/listing/listing14/12.webp';
import Appartment4_Img13 from '../../assets/images/listing/listing14/13.webp';
import Appartment4_Img14 from '../../assets/images/listing/listing14/14.webp';
import Appartment4_Img15 from '../../assets/images/listing/listing14/15.webp';


import SingleListing from './SingleListing';

const Villas = () => {

  return (
    <>
      <SingleListing
      Img1={Appartment1_Img1}
      Img2={Appartment1_Img2}
      Img3={Appartment1_Img3}
      Img4={Appartment1_Img4}
      Img5={Appartment1_Img5}
      Img6={Appartment1_Img6}
      Img7={Appartment1_Img7}
      Img8={Appartment1_Img8}
      Img9={Appartment1_Img9}
      Img10={Appartment1_Img10}
      Img11={Appartment1_Img11}
      Img12={Appartment1_Img12}
      Img13={Appartment1_Img13}
      Link="/villa-for-sale-dubai-dubai-south-dubai-world-central-emaar-south-greenviews-2-12319002"
      Title="3BR Villa"
      Address="Dubai"
      Category="Villa"
      Beds="3"
      Baths="4"
      SqFt="1,553 sqft / 144 sqm"
      Price="2,199,995"
      />  
      
      <SingleListing
      Img1={Appartment2_Img1}
      Img2={Appartment2_Img2}
      Img3={Appartment2_Img3}
      Img4={Appartment2_Img4}
      Img5={Appartment2_Img5}
      Img6={Appartment2_Img6}
      Img7={Appartment2_Img7}
      Img8={Appartment2_Img8}
      Img9={Appartment2_Img9}
      Img10={Appartment2_Img10}
      Img11={Appartment2_Img11}
      Img12={Appartment2_Img12}
      Img13={Appartment2_Img13}
      Img14={Appartment2_Img14}
      Img15={Appartment2_Img15}
      Link="/villa-for-sale-dubai-dubai-south-dubai-world-central-south-bay-south-bay-4-12484146"
      Title="5BR Mansion"
      Address="Dubai"
      Category="Villa"
      Beds="5"
      Baths="6"
      SqFt="8,418 sqft / 782 sqm"
      Price="11,000,000"
      />  

            <SingleListing
      Img1={Appartment3_Img1}
      Img2={Appartment3_Img2}
      Img3={Appartment3_Img3}
      Img4={Appartment3_Img4}
      Img5={Appartment3_Img5}
      Img6={Appartment3_Img6}
      Img7={Appartment3_Img7}
      Img8={Appartment3_Img8}
      Img9={Appartment3_Img9}
      Img10={Appartment3_Img10}
      Img11={Appartment3_Img11}
      Img12={Appartment3_Img12}
      Img13={Appartment3_Img13}
      Img14={Appartment3_Img14}
      Img15={Appartment3_Img15}
      Link="/villa-for-sale-dubai-dubai-south-dubai-world-central-south-bay-south-bay-5-12484195"
      Title="6BR Mansion"
      Address="Dubai"
      Category="Villa"
      Beds="6"
      Baths="7"
      SqFt="11,220 sqft / 1,042 sqm"
      Price="17,000,000"
      />  

            <SingleListing
      Img1={Appartment4_Img1}
      Img2={Appartment4_Img2}
      Img3={Appartment4_Img3}
      Img4={Appartment4_Img4}
      Img5={Appartment4_Img5}
      Img6={Appartment4_Img6}
      Img7={Appartment4_Img7}
      Img8={Appartment4_Img8}
      Img9={Appartment4_Img9}
      Img10={Appartment4_Img10}
      Img11={Appartment4_Img11}
      Img12={Appartment4_Img12}
      Img13={Appartment4_Img13}
      Img14={Appartment4_Img14}
      Img15={Appartment4_Img15}
      Link="/villa-for-sale-dubai-dubai-south-dubai-world-central-south-bay-south-bay-6-12484252"
      Title="7BR Mansion"
      Address="Dubai"
      Category="Villa"
      Beds="7"
      Baths="7"
      SqFt="13,657 sqft / 1,269 sqm"
      Price="21,000,000"
      />  
           
    </>
  );
}

export default Villas;
